import './App.css';
import './input.css';
import Page1 from './Page1';

function App() {
  return (
    <div className="App">
      <Page1/>
    </div>
  );
}

export default App;
